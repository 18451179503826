.site-navbar {
    margin-bottom: 0;
    z-index: 1999;
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    color: white;
  }
  
  @media (min-width: 992px) {
    .site-navbar .center-container {
        display: flex;
        position: relative;
        margin: auto;
    }

    .site-navbar .right-container {
        display: flex;
        position: absolute;
        right: 10px;
    }

    .site-navbar .nav-item {
      display: inline-block;
    }
  }

  .navmenu {
    background-color: #f44336;
    width: 100%;
  }
  
  .nav-item {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .nav-item:hover {
    border-color: white;
    border-bottom: solid;
  }
  
  .nav-link:hover {
    color: white !important;
  }
  
  .nav-link.active {
    font-weight: bold !important;
  }

  .navbar-logo {
    position: relative;
    left: 15px;
    font-family: 'AvenirNext-DemiBoldItalic';
  }

  @media screen and (max-width: 992px) {
    .nav-link {
        text-align: center;
    }

    .navbar-logo {
      margin-left: calc(50% - 26.5px);
      left: 0px!important;
    }
  }
  
  @media screen and (max-width: 992px) {
    .page {
      height: calc(100vh - 69px);
      top: 69px;
    }
  }
  
  @media (min-width: 992px) {
    .navbar-nav {
      width: 95%;
    }
  }
  
  @media (max-width: 500px) {
    .no-gutters-sm {
      margin-right: 0;
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .navbar-toggler {
    margin-right: 15px !important;
    width: 55px;
}