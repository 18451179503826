.headerElement {
    font-size: 1.5vh;
}

.scoreboard {
    width: 100%;
    background-color: white;
  }

  .header {
    align-items: center;
    border-bottom-style: solid;
    border-width: 1px;
    color: black;
}

.team {
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 0.9rem;
    width: 100%;
    flex-wrap: nowrap!important;
    height: 40px;
}

.team-name {
    width: -webkit-fill-available;
    white-space: nowrap;     
    overflow: hidden;        
    text-overflow: ellipsis;  
    padding: 0!important;
    padding-left: 1%!important;
}

.team-score {
    max-width: 5%!important;
    text-align: center;
    margin-right: 15px!important;
}

.team-logo {
    max-width: 40px!important;
    padding: 0!important;
    padding-right: 1%!important;
    max-height: 40px;
    text-align: center;
}

.team-logo-img {
    max-width: 100%!important;
    max-height: 40px!important;
}

.win {
    font-weight: bold;
}

.win .team {
    color: black;
    font-weight: bold;
}

.periodHeader {
    align-items: center;
    border-bottom-style: solid;
    border-width: 1px;
    color: black;
}

.periodScore {
    text-align: right;
    padding-right: 25px;
}

.scoreboard-content {
    display: flex;
    align-items: center;
}

.scoreboard-score {
    width: -webkit-fill-available;
    border-right-style: solid;
    border-right-color: lightgray;
    border-right-width: 1px;
}

.scoreboard-info {
    font-size: 0.6rem;
    text-align: center;
    height: 100%;
    align-content: center;
    margin-right: 0;
    display: grid;
    padding: 5px;
    width: 100px;
}


