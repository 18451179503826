/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
    padding: 5%;
}

/* Modal Content */
.modal-content {
    background-color: white!important;
    padding: 20px;
    border-radius: 10px!important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.modal-content h2 {
    margin-bottom: 10px;
    color: black;
}

.modal-content p {
    margin-bottom: 20px;
    color: black;

}

.modal-content button {
    padding: 10px 20px;
    background-color: #f44336; /* Red button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-content button:hover {
    background-color: #d32f2f; /* Darker red on hover */
}