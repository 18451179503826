/* Site Defaults */
:root {
  --primary-color: #f44336;
  --secondary-color: #ffffff;
  --background-color: #f0f0f0;
  --default-btn-hover-color: #fafafa;
  --default-border-radius: 10px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
}

p {
 color: white;
}

a {
  transition: 0.3s all ease;
  text-decoration: none;
  color: inherit;

}

/* Footer Style */
.footer-container {
  background-color: lightgray;
  color: #f44336!important;
  padding: 1rem;
  text-align: center;
}

.footer-container p{
  color: #f44336!important;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 10px;
}

.social-links a {
  color: #f44336;
  transition: transform 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1);
}

.red-btn {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.75rem 1rem;
  border-radius: var(--default-border-radius);
  text-decoration: none;
}

.red-btn:hover {
  background-color: #a52727!important;
}

.black-btn {
  background-color: black;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: var(--default-border-radius);
  text-decoration: none;
}

.black-btn:hover {
  background-color: #202020!important;
}

/*  Style */
.width-max {
  width: 100%;
}

.InfoElement {
  width: auto;
  position: inherit;
  left: 0;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .page {
      height: calc(100vh - 69px);
      top: 69px;
  }
}

@media screen and (max-width: 768px) {
  .InfoElement {
      display: inline-block;
  }

  .center-home-container {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    height: fit-content!important; 
  }
}

/* Page Layout */
.page {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 52.5px);
  position: absolute;
  width: 100%;
  top: 52.5px;
}

.container {
  display: grid;
  max-width: 100%;
  height: 100%;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.standard-container {
  background-color: white;
  padding: 20px;
  border-radius: var(--default-border-radius);
  margin-top: 20px;
  display: grid;
}

.account-container {
  background-color: white;
  padding: 20px;
  border-radius: var(--default-border-radius);
}

.account-container h2{
  text-align: center;
  color: black;

}

.account-container p{
  text-align: center;
  color: black;
}


.login-cancel {
  display: flex;
  justify-content: space-between; /* Push the Cancel button to the left and the span to the right */
  align-items: center; /* Ensure both elements are vertically aligned */
}

.infoContainer {
  max-width: 100vw;
  width: 100vw;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #d8d8d8 !important;
  padding-top: 10px;
}

.img-home-info {
  max-width: 100%;
  width: fit-content;
}

.center-home-container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  height: 100%; 
}

.center-text {
  text-align: center;
}

.margin-auto {
  margin: auto !important;
}

.display-flex {
  display: flex !important;
}

.bg-white {
  background-color: white !important;
}

.bg-red {
  background-color:#f44336!important;
}

.height-max {
  height: 100%;
}

.text-center {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

/* Buttons */
button {
  background-color: #04aa6d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

.cancelbtn {
  width: fit-content;
  color: white;
  padding: 10px 18px;
  background-color: #f44336;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
}

.cancelbtn:hover {
  opacity: 0.8;
}

.confirm-btn {
  background-color: green!important;
}


/* Containers */
.containerPad {
  padding-top: 50px !important;
}

.margin-auto {
  margin: auto;
}

.pageHeader {
  color: white;
  text-align: center;
  text-decoration: underline;
  font-size: 3vh;
}

/* Form Styles */
input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  background-color: #ddd;
  outline: none;
}

/* Loading Screen for ConfirmPayment */
.loading {
  position: fixed;
  z-index: 999999;
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

/* Modal */
.modal {
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 60px;
}

.modal-content {
  background-color: #fefefe!important;
  margin: 5% auto;
  border: 1px solid #888 !important;
  border-radius: var(--default-border-radius);
  width: 50%;
}

/* Responsive */
@media screen and (max-width: 992px) {
  .mobileHomePage {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 768px) {
  .mobileMainPadding {
    padding-top: 40vh !important;
  }

  .indexContainerPad {
    padding-top: 50px;
  }
}

.horizontal-scroll-menu {
  width: 100% !important;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #f44336;
  height: fit-content;
}

.scrollmenu {
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  white-space: nowrap;
  width: 100vw;
  display: flex;
  justify-content: center;
  margin: auto;
  overflow: auto;
}

.scrollmenu a {
  display: inline-block;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

@media screen and (max-width: 510px) {

  .horizontal-scroll-menu {
      width: 100% !important;
      display: flex;
      justify-content: center;
      height: fit-content !important;
  }
  .scrollmenu {
    overflow: auto;
    width: 90%;
    justify-content: flex-start;
}
}

/* Small Screen Adjustments */
@media screen and (max-width: 500px) {
  .sm-align-end {
    align-content: end !important;
  }

  .no-score-info {
    color: black;
    width: 100%;
  }

  .scroll-view {
    background-color: lightgray;
    padding: 20px!important;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1000px) {

  .scroll-view {
    padding: 20px!important;
    padding-left: 200px!important;
    padding-right: 200px!important;
    margin-bottom: 20px;
  }
}

.scroll-view {
  background-color: lightgray;
  padding: 50px;
  flex-grow: 1;
  margin-bottom: 0px;
}

.bottom-border {
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
  padding-bottom: 10px;
}

.score-type-picker {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-height: 75px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
 
.team-header {
  display: flex;
  max-height: 75px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
}

.team-header-img {
  max-width: 50px;
  max-height: 50px;
  margin-left: 15px;
  margin-right: 15px;
}

.team-header-text {
  width: 100%;
  display: grid;
  align-items: center;
}

.media-container {
  display: grid;
  grid-gap: 20px;
}

.media-item {
  display: grid;
  color: black!important;
  text-decoration: none!important;
  background-color: white;
  border-radius: var(--default-border-radius);
  padding: 25px;
}

.media-item-image {
  width: 100%;
  position: relative;
  overflow: hidden;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.media-item-text {
  padding-top: 10px;
}

.player-card {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between ;
}

.player-name {
  width: 25%;
}

.standings-table {
  width: 100%;
}

.standings-table td {
  font-size: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;   
  max-width: 150px;
}

.standings-table th {
  font-weight: bold;
}

.event-info-header {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
}

.event-info-header-team-home {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: calc(50% - 50px);
  justify-content: end;
}

.event-info-header-team-home h2 {
  font-size: 1rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;   
  width: -webkit-fill-available;
  text-align: center;
}


.event-info-header-team-away {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: calc(50% - 50px);
}

.event-info-header-team-away h2 {
  font-size: 1rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;   
  width: -webkit-fill-available;
  text-align: center;
}

.event-header-img {
  max-width: 50px;
  max-height: 50px;
  margin-left: 15px;
  margin-right: 15px;
}


/* Small Screen Adjustments */
@media screen and (max-width: 450px) {
  .event-header-img {
    max-width: 25%;
    margin-left: 15px;
    margin-right: 15px;
  }

  .team-header-img {
    max-width: 25%;
    margin-left: 15px;
    margin-right: 15px;
  }
}

  
.text-center p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}